import React, { useState, useEffect } from "react";
import ProjectElement from "./ProjectElement";

const ProjectList = ({ dataProject }) => {
  const [projects, setProjects] = useState({});

  useEffect(() => {
    setProjects(dataProject);
  }, [dataProject]);

  return (
    <section className="section">
      <h2 className="title" id="projectList">
        Project
      </h2>
      <ul className="list list_project">
        {Object.values(projects).map((val, index) => {
          return <ProjectElement key={index} data={val} />;
        })}
      </ul>
    </section>
  );
};
export default ProjectList;
