import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const HomeText = ({ dataTextBig }) => {
  const [textBig, setTextBig] = useState([]);
  useEffect(() => {
    const data = dataTextBig;
    setTextBig(data.split(" "));
  }, [dataTextBig]);

  const initial = { y: "200%", skew: 30 };

  const animate = { y: 0, skew: 0 };

  return (
    <section className="section section_text-wrapper">
      <p className="text_big">
        {textBig.map((word, index, textBig) => {
          return (
            <span
              className={
                textBig.length - 1 !== index
                  ? "text_wrapper"
                  : "text_wrapper last_word-wrapp"
              }
              key={index}
            >
              <motion.span
                initial={initial}
                animate={animate}
                transition={{
                  delay: index * 0.3,
                  duration: 0.7,
                }}
                className={
                  textBig.length - 1 !== index ? "word" : "word last_word"
                }
                key={index}
              >
                {word}
              </motion.span>
            </span>
          );
        })}
      </p>
    </section>
  );
};
export default HomeText;
