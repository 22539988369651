import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import FlyImg from "./FlyImg";

const ProjectElement = ({ data }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <motion.li
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        className="project_list"
      >
        <Link className="link link_project" to={`/project/${data.link}`}>
          {data.title}
        </Link>

        <FlyImg data={data.img} hovered={isHovered} />
      </motion.li>
    </>
  );
};
export default ProjectElement;
