import React from "react";
const About = ({ dataAbout }) => {
  return (
    <section className="section">
      <h2 className="title" id="aboutMe">
        About me
      </h2>
      <div className="wrapper_about">
        <p className="text_about">{dataAbout.text}</p>
        <img
          className="img img_about"
          src={`img/${dataAbout.img}`}
          alt="cuozzo vincenzo"
        />
      </div>
    </section>
  );
};

export default About;
