import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";

const Project = ({ data }) => {
  const [dataProject, setDataProject] = useState({});
  const [techList, setTechList] = useState({});

  const { projectId } = useParams();
  useEffect(() => {
    setDataProject(data[projectId]);
    setTechList(data[projectId].tech_list);
  }, [projectId, data, setDataProject, setTechList]);

  let max = 20;
  let min = 10;
  let random = Math.floor(Math.random() * (max - min + 1) + min);

  const initialLeft = {
    opacity: 0,
    x: -random,
    y: -random,
  };
  const initialRight = {
    opacity: 0,
    x: random,
    y: random,
  };
  const animate = {
    opacity: 1,
    x: 0,
    y: 0,
  };
  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, translate: "50%" }}
      transition={{ duration: 0.5 }}
      className="section section_page-project"
    >
      <motion.h2
        initial={initialLeft}
        animate={animate}
        transition={{
          delay: 0.4,
        }}
        className="title title_project"
      >
        {dataProject.title}
      </motion.h2>
      <motion.p
        initial={initialLeft}
        animate={animate}
        transition={{
          delay: 0.5,
        }}
        className="text_project"
      >
        {dataProject.text}
      </motion.p>
      <motion.h4
        initial={initialLeft}
        animate={animate}
        transition={{
          delay: 0.6,
        }}
        className="title title_tech-list"
      >
        tech_list:
      </motion.h4>
      <motion.ul
        initial={initialLeft}
        animate={animate}
        transition={{
          delay: 0.6,
        }}
        className="list list_tech"
      >
        {Object.values(techList).map((val, index) => {
          return (
            <li key={index}>
              <p>– {val}</p>
            </li>
          );
        })}
      </motion.ul>
      <motion.div
        initial={initialRight}
        animate={animate}
        transition={{
          delay: 0.3,
        }}
        className="wrapper_project"
      >
        <img
          className="img img_project"
          src={`../img/projectImg/${dataProject.img}.jpg`}
          srcSet={`../img/projectImg/${dataProject.img}@2x.jpg 2x`}
          alt={`project ${dataProject.title}`}
        />
        <a
          className="link link_page-project"
          href={dataProject.ext_link}
          target="_blank"
          rel="noreferrer"
        >
          Visit project
        </a>
      </motion.div>
    </motion.section>
  );
};

export default Project;
