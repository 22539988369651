import React from "react";
import HomeText from "../components/HomeText";
import ProjectList from "../components/ProjectList";
import About from "../components/About";
import Separator from "../components/Separator";
import { motion } from "framer-motion";
const Home = ({ data }) => {
  const dataHome = data.dataHome;
  const dataProject = data.dataProject;

  const dataAbout = {
    img: dataHome.imgAbout,
    text: dataHome.textAbout,
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, translate: "-50%" }}
      transition={{ duration: 0.5 }}
    >
      <HomeText dataTextBig={dataHome.textBig} />
      <Separator />
      <ProjectList dataProject={dataProject} />
      <Separator />
      <About dataAbout={dataAbout} />
    </motion.div>
  );
};

export default Home;
