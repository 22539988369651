import React from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { HashLink as Link } from "react-router-hash-link";

const Header = () => {
  let location = useLocation();

  return (
    <motion.nav
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="menu"
    >
      <h1 className="title title_menu">
        <Link className="link link_title" to="/">
          Cuozzo
          <br />
          Vincenzo
        </Link>
      </h1>

      {location.pathname !== "/" ? (
        <Link
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          exit={{ opacity: 0, translate: "-50%" }}
          className="link link_header"
          to="/#projectList"
        >
          Project
        </Link>
      ) : (
        <motion.a
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          exit={{ opacity: 0, translate: "-50%" }}
          className="link link_header"
          href="#aboutMe"
        >
          About me
        </motion.a>
      )}
    </motion.nav>
  );
};

export default Header;
