import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const FlyImg = ({ data, hovered }) => {
  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    const mouseMove = (e) => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY,
      });
    };
    window.addEventListener("mousemove", mouseMove);
    return () => {
      window.removeEventListener("mousemove", mouseMove);
    };
  }, [hovered]);

  return (
    <AnimatePresence mode="wait">
      {hovered && (
        <motion.div
          className="wrapper_flyimg"
          initial={{
            x: mousePosition.x,
            y: mousePosition.y,
            opacity: 0,
            scale: 0,
          }}
          animate={{
            x: mousePosition.x,
            y: mousePosition.y,
            opacity: 0.2,
            scale: 1,
          }}
          exit={{
            x: mousePosition.x,
            y: mousePosition.y,
            opacity: 0,
            scale: 1,
          }}
          transition={{
            type: "spring",
            damping: 9.5,
            mass: 0.75,
            stiffness: 100,
          }}
        >
          <img
            className="img img_fly"
            src={`img/projectImg/${data}.jpg`}
            srcSet={`img/projectImg/${data}@2x.jpg 2x`}
            alt={data}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};
export default FlyImg;
