import React from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
const Footer = () => {
  let location = useLocation();
  return (
    <>
      <motion.footer
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, translate: "-50%" }}
        transition={{ duration: 0.5, delay: 0.5 }}
        className={
          location.pathname !== "/" ? "footer footer_project" : "footer"
        }
      >
        <p>hello@cuozzovincenzo.be</p>
        <p>make with 💚 and ☕</p>
      </motion.footer>
    </>
  );
};
export default Footer;
