import React from "react";
import { motion, AnimatePresence } from "framer-motion";

const Loader = ({ load }) => {
  return (
    <AnimatePresence mode="wait">
      {!load && (
        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key="mainLoader"
          transition={{ duration: 0.5 }}
          className="section_loader"
        >
          <div className="wrapper_loader">
            <div className="loader"></div>
          </div>
        </motion.section>
      )}
    </AnimatePresence>
  );
};

export default Loader;
