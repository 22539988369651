import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "../pages/Home";
import Project from "../pages/Project";
import Footer from "./Footer";
import { AnimatePresence } from "framer-motion";

const AnimatedRoutes = ({ data }) => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route exact path="/" element={<Home data={data} />} />
        <Route
          exact
          path="/project/:projectId"
          element={<Project data={data.dataProject} />}
        />

        <Route path="*" element={<div>404</div>} />
      </Routes>
      <Footer key={"footer"} />
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
