import React, { useEffect, useState } from "react";
import "./firebase-config";
import "./styles/index.scss";
import Header from "./components/Header";
import AnimatedRoutes from "./components/AnimatedRoutes";
import Loader from "./components/Loader";
import Cursor from "./components/Cursor";
import { BrowserRouter as Router } from "react-router-dom";
import {
  getDatabase,
  ref,
  onValue,
  query,
  orderByChild,
} from "firebase/database";

const App = () => {
  const [dataHome, setDataHome] = useState(null);
  const [dataProject, setDataProject] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isHovered, setIsHovered] = useState("default");
  const [sizeScreenCursor, setSizeScreenCursor] = useState(false);
  useEffect(() => {
    const db = getDatabase();
    const refDataHome = ref(db, "home");
    onValue(refDataHome, (snapshot) => {
      const data = snapshot.val();
      setDataHome(data);
    });
    const refDataProject = query(ref(db, "project"), orderByChild("/order"));
    onValue(refDataProject, (snapshot) => {
      const data = snapshot.val();
      setDataProject(data);
    });

    if (window.innerWidth >= 800) {
      setSizeScreenCursor(true);
    } else {
      setSizeScreenCursor(false);
    }
  }, []);
  if (dataHome !== null && dataProject !== null) {
    setTimeout(() => {
      setIsLoaded(true);
      const link = document.querySelectorAll(".link");
      link.forEach((val) => {
        val.addEventListener("mouseenter", () => {
          setIsHovered("link");
        });
        val.addEventListener("mouseleave", () => {
          setIsHovered("default");
        });
      });
    }, 2000);
  }

  return (
    <Router basename="/">
      {sizeScreenCursor && <Cursor hovered={isHovered} />}
      <Loader load={isLoaded} />

      {isLoaded && (
        <>
          <Header />
          <AnimatedRoutes data={{ dataHome, dataProject }} />
        </>
      )}
    </Router>
  );
};
export default App;
