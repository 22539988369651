import React, { useEffect, useLayoutEffect, useState } from "react";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";

const Cursor = ({ hovered }) => {
  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0,
  });
  const [cursorVariant, setCursorVariant] = useState("default");

  const location = useLocation();

  useEffect(() => {
    setCursorVariant(hovered);
    const mouseMove = (e) => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY,
      });
    };
    window.addEventListener("mousemove", mouseMove);
    return () => {
      window.removeEventListener("mousemove", mouseMove);
    };
  }, [hovered]);
  useLayoutEffect(() => {
    if (location.pathname) {
      setCursorVariant("default");
    }
  }, [location.pathname]);
  const initial = {
    x: mousePosition.x,
    y: mousePosition.y,
    scale: 0,
    opacity: 0,
  };

  const variants = {
    default: {
      x: mousePosition.x,
      y: mousePosition.y,
      scale: 1,
      opacity: 1,
    },
    link: {
      height: 100,
      width: 100,
      top: -50,
      left: -50,
      x: mousePosition.x,
      y: mousePosition.y,
      backgroundColor: "#2b2b2b",
      mixBlendMode: "difference",
      scale: 1,
      opacity: 1,
    },
  };
  const variantsDot = {
    default: {
      x: mousePosition.x,
      y: mousePosition.y,
      scale: 1,
      opacity: 1,
    },
    link: {
      height: 100,
      width: 100,
      top: -50,
      left: -50,
      x: mousePosition.x,
      y: mousePosition.y,
      scale: 1,
      opacity: 1,
    },
  };

  return (
    <>
      <motion.span
        initial={initial}
        variants={variantsDot}
        animate={cursorVariant}
        exit={variants.default}
        transition={{
          duration: false,
        }}
        className="cursor cursor-dot"
      ></motion.span>
      <motion.span
        initial={initial}
        variants={variants}
        animate={cursorVariant}
        transition={{
          type: "spring",
          damping: 15,
          mass: 1,
          stiffness: 75,
        }}
        className="cursor cursor-circle"
      ></motion.span>
    </>
  );
};

export default Cursor;
