// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCju3Ta4n6UtBNLPcf139JEFwPtWXwdFGE",
  authDomain: "portfolio-6e66a.firebaseapp.com",
  databaseURL:
    "https://portfolio-6e66a-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "portfolio-6e66a",
  storageBucket: "portfolio-6e66a.appspot.com",
  messagingSenderId: "441811483997",
  appId: "1:441811483997:web:91ba97192945b580897cb4",
  measurementId: "G-SMTK3JXKJ4",
};

// Initialize Firebase
initializeApp(firebaseConfig);
